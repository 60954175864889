import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

export type SEOProps = {
    title?: string;
    specialTitle?: string;
    description?: string;
    imageUrl?: string;
    slug: string;
    type?: string;
}

const SEO = ({ title, specialTitle, description, imageUrl, slug, type }: SEOProps) => {
    const data = useStaticQuery(graphql`
        query SEOQuery {
            site {
                siteMetadata {
                    title
                    description
                    imageUrl
                    siteUrl
                }
            }
        }
    `);

    const seoTitle = specialTitle ? specialTitle : title ? `${title} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title
    const seoDescription = description || data.site.siteMetadata.description;
    const seoImageUrl = imageUrl || data.site.siteMetadata.imageUrl;
    const seoType = type || 'website';
    const canonical = data.site.siteMetadata.siteUrl + slug;

    return (
        <Helmet htmlAttributes={{ lang: 'en' }}>
            <meta charSet="utf-8" />
            <link rel="canonical" href={ canonical } />
            <title>{ seoTitle }</title>
            <meta name="description" content={ seoDescription } />
            <link rel="icon" href="/assets/favico.ico" />

            {/* OpenGraph tags */}
            <meta name="og:url" content={ canonical } />
            <meta name="og:title" content={ seoTitle } />
            <meta name="og:description" content={ seoDescription } />
            <meta name="og:image" content={ seoImageUrl } />
            <meta name="og:type" content={ seoType } />
            <meta name="og:locale" content="en_US" />

            {/* Twitter tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={ seoTitle } />
            <meta name="twitter:description" content={ seoDescription } />
            <meta name="twitter:image" content={ seoImageUrl } />

            {/* Other meta tags */}
            <meta name="author" content="AntDev" />
            <meta name="google-site-verification" content="47IyEGCYNZtinZKgQ2UPbwb1C00Zv37x17O0ZzhGSfY" />
        </Helmet>
    );
};

export default SEO;
