import React from 'react';
import SEO, { SEOProps } from './seo';
import Header from './header';
import Footer from './footer';

type LayoutProps = {
    children: React.ReactNode;
} & SEOProps;

const Layout = ({ children, title, specialTitle, description, imageUrl, slug, type }: LayoutProps) => {
    return (
        <>
        <SEO
            title={title}
            specialTitle={specialTitle}
            description={description}
            imageUrl={imageUrl}
            slug={slug}
            type={type}
        />
        <Header />
        <div className="page-container">
            { children }
        </div>
        <Footer />
        </>
    );
};

export default Layout;
