import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import routes from '../../constants/routes';
import * as styles from '../../styles/modules/footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerMain}>
                <div className={styles.footerInfoContainer}>
                    <div className={styles.footerInfoLogo}>
                        <StaticImage src="../../images/logo_white.svg" alt="ACB Footer Logo" />
                    </div>
                    <div className={styles.footerInfo}>
                        <p>3420 Electric Road, Suite 1A</p>
                        <p>Roanoke, VA 24018</p>
                        <p><a href="tel:+15409041293">(540) 904-1293</a></p>
                        <p>Class A Contractor</p>
                        <p>License: 2705120687</p>
                    </div>
                </div>
                <div className={styles.footerNavContainer}>
                    <ul className="footer-nav__main">
                        <li className="footer-nav__link"><Link to={routes.home}>Home</Link></li>
                        <li className="footer-nav__link"><Link to={routes.about}>About Us</Link></li>
                        <li className="footer-nav__link"><Link to={routes.testimonials}>Testimonials</Link></li>
                        <li className="footer-nav__link"><Link to={routes.vendors}>Vendors</Link></li>
                    </ul>
                    <ul className="footer-nav__gallery">
                        <li className="footer-nav__link"><Link to={routes.gallery_custom}>Custom Homes</Link></li>
                        <li className="footer-nav__link"><Link to={routes.gallery_remodels}>Remodels</Link></li>
                        <li className="footer-nav__link"><Link to={routes.gallery_additions}>Additions</Link></li>
                        <li className="footer-nav__link"><Link to={routes.gallery_garages}>Garages</Link></li>
                    </ul>
                    <ul className="footer-nav__resources">
                        <li className="footer-nav__link"><Link to={routes.faq}>FAQ</Link></li>
                        <li className="footer-nav__link"><Link to={routes.building_101}>Building 101</Link></li>
                        <li className="footer-nav__link"><Link to={routes.what_to_expect}>What to Expect</Link></li>
                        {/*<li className="footer-nav__link"><Link to={routes.our_process}>Our Process</Link></li>*/}
                    </ul>
                </div>
            </div>
            <div className={styles.footerLegal}>
                <p className="copyright">Copyright &copy; { new Date().getFullYear() } Alouf Custom Builders, Inc.</p>
            </div>
        </footer>
    );
};

export default Footer;
