import React from 'react';
import { Link } from 'gatsby';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import routes from '../../constants/routes';

const Header = () => {
    return (
        <Navbar className="Header" expand="lg" bg="primary" variant="dark" sticky="top">
            <Navbar.Brand className="d-block d-lg-none">
                Alouf Custom Builders
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="nav" />
            <Navbar.Collapse id="nav">
                <Nav className="ml-auto mr-auto">
                    <Link to={routes.home} className="navbar-dark navbar-nav nav-link">Home</Link>
                    <Link to={routes.about} className="navbar-dark navbar-nav nav-link">About Us</Link>
                    <NavDropdown title="Gallery" id="gallery-dropdown">
                        <Link to={routes.gallery_custom} className="dropdown-item">Custom Homes</Link>
                        <Link to={routes.gallery_remodels} className="dropdown-item">Remodels</Link>
                        <Link to={routes.gallery_additions} className="dropdown-item">Additions</Link>
                        <Link to={routes.gallery_garages} className="dropdown-item">Garages</Link>
                    </NavDropdown>
                    <Link to={routes.testimonials} className="navbar-dark navbar-nav nav-link">Testimonials</Link>
                    <Link to={routes.vendors} className="navbar-dark navbar-nav nav-link">Vendors</Link>
                    <NavDropdown title="Resources" id="resources-dropdown">
                        <Link to={routes.faq} className="dropdown-item">FAQ</Link>
                        <Link to={routes.building_101} className="dropdown-item">Building 101</Link>
                        <Link to={routes.what_to_expect} className="dropdown-item">What to Expect</Link>
                        {/*<Link to={routes.our_process} className="dropdown-item">Our Process</Link>*/}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
